<template>
  <div>
    <!-- 頁面: 會員列表(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div v-if="checkAuthAbility('customer', 'API.Admin.Customer')">
      <update-modal
        v-if="selected"
        ref="updateModal"
        :customer-data-item="selected"
        @refetch-data="refetchTable"
      />

      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>篩選器</h5>
        </b-card-header>

        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              lg="2"
              md="3"
              class="mb-md-0 mb-1"
            >
              <label>狀態</label>
              <v-select
                v-model="searchState"
                :options="stateOptions"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                    />
                    {{ option.label }}
                  </div>
                </template>

                <template #selected-option="option">
                  <div class="d-flex align-items-center">
                    <span
                      class="mr-50 border state-dot"
                      :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="3"
              md="4"
              class="mb-md-0 mb-1"
            >
              <label>分站</label>
              <v-select
                v-model="searchBranch"
                :options="branchOptions"
                class="w-100"
                :reduce="option => option.value"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>

                <template v-slot:option="option">
                  <div>
                    <div
                      v-if="option.label"
                      class="font-weight-bold d-block text-nowrap mb-0"
                    >
                      {{ option.label }}<small class="text-muted">( {{ option.key }} )</small>
                    </div>

                    <div v-else>
                      {{ option.key }}
                    </div>
                  </div>
                </template>

                <template #selected-option="option">
                  <div>
                    <div
                      v-if="option.label"
                      class="font-weight-bold d-block text-nowrap mb-0"
                    >
                      {{ option.label }}<small class="text-muted">( {{ option.key }} )</small>
                    </div>

                    <div v-else>
                      {{ option.key }}
                    </div>
                  </div>
                </template>
              </v-select>
            </b-col>

            <b-col
              cols="12"
              lg="2"
              md="3"
              class="mb-md-0 mb-1"
            >
              <label>驗證狀態</label>
              <v-select
                v-model="searchKycState"
                :options="[
                  { label: '全部', value: false },
                  { label: '待驗證', value: true }
                ]"
                class="w-100"
                :reduce="option => option.value"
                :clearable="false"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>
          </b-row>

        </b-card-body>
      </b-card>

      <b-card no-body>
        <div class="mx-2 mt-2 mb-50">
          <b-row>
            <b-col
              cols="12"
              md="8"
              sm="6"
            >
              <h4 class="mb-0">
                會員列表
              </h4>
            </b-col>

            <b-col
              cols="12"
              md="4"
              sm="6"
            >
              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-if="checkAuthAbility('customer', 'API.Admin.Customer.Create')"
                  v-b-modal.add-modal
                  v-b-tooltip.hover.focus.v-secondary
                  title="新增"
                  class="d-flex align-items-center actions-link-btn"
                  @click="onSubmitUpdate(null)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/plus.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="refetchTable"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="列表顯示"
                  class="d-flex align-items-center actions-link-btn ml-25"
                >
                  <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <b-img
                        src="/dashboard/admin/images/table/setting.svg"
                        class="actions-link-btn-image"
                        rounded
                      />
                    </template>

                    <b-dropdown-form>
                      <b-form-group>
                        <label class="mb-50">
                          列表顯示
                        </label>

                        <b-form-checkbox
                          v-for="column in tableColumnsSearchable"
                          :key="column.key"
                          v-model="column.select"
                          name="table-column"
                          class="mb-1"
                        >
                          {{ column.label }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-dropdown-form>

                  </b-dropdown>
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="創建日期"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  :class="timeRange ? 'actions-link-btn-active' : null"
                  @click="() => { $refs.TableTimeRange.getData(timeRange) }"
                >
                  <b-img
                    src="/dashboard/admin/images/table/calendar.svg"
                    class="actions-link-btn-image"
                    rounded
                  />

                  <TableTimeRange
                    ref="TableTimeRange"
                    table-explorer-id="TableTimeRange"
                    @call-back-data="time => timeRange = time"
                  />
                </div>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 顯示個數/搜尋框 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示個數 -->
            <b-col
              cols="12"
              md="6"
              sm="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="table-perPage-select"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    沒有<em>{{ search }}</em> 的相關結果
                  </template>

                  <div v-else>
                    <small>暫無資料</small>
                  </div>
                </template>
              </v-select>
            </b-col>

            <!-- 搜尋框 -->
            <b-col
              cols="12"
              md="6"
              sm="8"
            >
              <!-- 搜尋框 -->
              <div class="w-100 mr-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    debounce="500"
                    class="d-inline-block"
                    placeholder="搜尋..."
                  />
                  <b-input-group-append
                    v-if="searchQuery"
                    is-text
                  >
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer text-muted"
                      @click="searchQuery = null"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>

          </b-row>
        </div>

        <!-- 列表 -->
        <b-table
          ref="refDataListTable"
          :items="getCustomerListData"
          :fields="tableColumnsFilter"
          responsive
          striped
          hover
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
          :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <!-- 忙碌中 -->
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="tableColumnsFilter.length"
              hide-header
              :table-props="{ bordered: true, striped: true }"
            />
          </template>

          <!-- 查無資料 -->
          <template #empty>
            <div class="text-center my-5 animate__animated animate__fadeIn">
              <b-img
                :src="$store.state.app.themeImages.notFoundImg"
                fluid
                width="480"
                alt="查無資料"
              />
            </div>
          </template>

          <!-- 欄位: 編號 -->
          <template #cell(id)="data">
            <div class="table-col">
              <span>{{ data.item.id }}</span>
            </div>
          </template>

          <!-- 欄位: 會員名稱 -->
          <template #cell(name)="data">
            <div class="d-flex align-items-center">
              <div @click="onSubmitRemark(data.item)">
                <b-avatar
                  variant="secondary"
                  :src="data.item.image"
                />
              </div>

              <div class="ml-50 w-100">
                <div class="d-flex align-items-end">
                  <b-link
                    class="font-weight-bold d-block text-nowrap show-text"
                    @click="linkCustomerInfo(data.item)"
                  >
                    {{ data.item.name }} {{ data.item.family_name }}
                  </b-link>

                  <div
                    v-if="data.item.remark_display"
                    @click="onSubmitRemark(data.item)"
                  >
                    <b-img
                      src="/dashboard/admin/images/table/chat-remark.svg"
                      height="20"
                      width="20"
                      class="cursor-pointer mb-25 ml-50"
                    />
                  </div>
                </div>
                <small class="text-muted">
                  <div class="email-text">{{ data.item.account }}</div>
                </small>
              </div>
            </div>
          </template>

          <!-- 欄位: 分站 -->
          <template #cell(branch_id)="data">
            <div
              class="d-block text-nowrap show-text table-point-link"
              @click="linkBranchInfo(data.item)"
            >
              <span v-if="data.item.branch_info && data.item.branch_info.name">{{ data.item.branch_info.name }}</span>
              <span v-else>
                {{ data.item.branch_info.branch }}
              </span>
            </div>
          </template>

          <!-- 欄位: 驗證狀態 -->
          <template #cell(verify)="data">
            <div class="text-nowrap d-flex">
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="信箱驗證"
                rounded
                fluid
                class="user-state-icon"
                :style="{ 'opacity': data.item.email_state === 2 ? 1 : 0.3 }"
                :src="require('@/assets/images/pages/customer/email.svg')"
              />

              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="電話驗證"
                rounded
                fluid
                class="user-state-icon"
                :style="{ 'opacity': data.item.phone_state === 2 ? 1 : 0.3 }"
                width="20"
                :src="require('@/assets/images/pages/customer/security.svg')"
              />

              <div class="user-state-alert">
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="身分驗證"
                  rounded
                  fluid
                  class="user-state-icon mr-0"
                  :style="{ 'opacity': data.item.kyc_state === 2 ? 1 : 0.3 }"
                  :src="require('@/assets/images/pages/customer/id-card.svg')"
                />

                <b-img
                  v-if="data.item.kyc_state === 3"
                  v-b-tooltip.hover.focus.v-secondary
                  title="待驗證"
                  class="alert-icon"
                  :src="require('@/assets/images/commen/warning-yellow.svg')"
                />
              </div>

            </div>
          </template>

          <!-- 欄位: 餘額 -->
          <template #cell(point)="data">
            <div class="text-nowrap table-col">
              $ {{ parseInt(data.item.point, 10).toLocaleString() }}
            </div>
          </template>

          <!-- 欄位: 上次登入 -->
          <template #cell(last_login_ip)="data">
            <div class="table-title">
              <div v-if="data.item.last_login_ip">
                <div>{{ data.item.last_login_ip ? data.item.last_login_ip : '未知' }}</div>
                <small
                  v-if="data.item.last_login_at"
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.last_login_at).format('HH:mm')}`"
                  class="text-muted"
                >
                  <div class="email-text">{{ updateOnline(data.item.last_login_at, refonlineTime) }}</div>
                </small>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 狀態 -->
          <template #cell(state)="data">
            <div class="table-col">
              <div
                v-if="data.item.state"
                v-b-tooltip.hover.focus.v-secondary
                title="啟用"
                class="state-dot dot-green"
                @click="onSubmitUpdate(data.item)"
              />
              <div
                v-else
                v-b-tooltip.hover.focus.v-secondary
                title="停用"
                class="state-dot dot-gray"
                @click="onSubmitUpdate(data.item)"
              />
            </div>
          </template>

          <!-- 欄位: 新增時間 -->
          <template #cell(created_at)="data">
            <div style="min-width: 85px;">
              <div v-if="data.item.created_at">
                <span
                  v-b-tooltip.hover.focus.v-secondary
                  :title="`${moment(data.item.created_at).format('HH:mm')}`"
                >
                  {{ updateOnline(data.item.created_at, refonlineTime) }}
                </span>
              </div>

              <div v-else>
                <span>---</span>
              </div>
            </div>
          </template>

          <!-- 欄位: 動作 -->
          <template #cell(actions)="data">
            <div class="d-flex mb-50">
              <div
                v-if="checkAuthAbility('remark', 'API.Admin.Remark')"
                class="link-remark-botton-alert actions-link-btn mr-25"
                @click="onSubmitRemark(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="備註留言"
                  src="/dashboard/admin/images/table/chat.svg"
                  class="actions-link-btn-image"
                  rounded
                />
                <div
                  v-if="data.item.remark_display"
                  class="state-dot dot-red"
                />
              </div>

              <div
                v-if="checkAuthAbility('customer', 'API.Admin.Customer.Update')"
                class="actions-link-btn mr-25"
                @click="onSubmitUpdate(data.item)"
              >
                <b-img
                  v-b-tooltip.hover.focus.v-secondary
                  title="編輯"
                  src="/dashboard/admin/images/table/edit.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-if="checkAuthAbility('customer', 'API.Admin.Customer.Id')"
                class="actions-link-btn"
              >
                <b-link @click="linkCustomerInfo(data.item)">
                  <b-img
                    v-b-tooltip.hover.focus.v-secondary
                    title="會員詳情"
                    src="/dashboard/admin/images/table/user.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </b-link>
              </div>
            </div>
          </template>
        </b-table>

        <!-- 顯示頁數/分頁 -->
        <div class="mx-2 mb-2">
          <b-row>
            <!-- 顯示頁數 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
            </b-col>

            <!-- 分頁 -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNum"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

      </b-card>

      <remark-modal
        ref="customerRemarkExplorerModal"
        remark-explorer-id="customerRemarkExplorerModal"
        :key-array="keyArray"
        :is-hiden-emit="true"
        :use-callback-data="false"
        @emit-hidden-function="refetchTable"
        @call-back-data="() => {}"
      >
        <template v-slot:header-info>
          <div class="mb-1" />
        </template>
      </remark-modal>
    </div>

    <div v-else>
      <b-alert
        variant="danger"
        :show="true"
      >
        <h4 class="alert-heading">
          權限不足
        </h4>
        <div class="alert-body">
          返回
          <b-link
            class="alert-link"
            :to="{ name: 'admin-home'}"
          >
            後台首頁
          </b-link>
        </div>
      </b-alert>
    </div>
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BAvatar, BLink,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BCardBody, BCardHeader, BInputGroupAppend, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import router from '@/router'
import store from '@/store'

import { useCustomerList, useCustomerSetting } from '../useCustomer'
import UpdateModal from './CustomerListUpdateModal.vue'
import remarkModal from '@/layouts/components/Modal/remark-modal/remarkModal.vue'
import TableTimeRange from '@/layouts/components/Table/TimeRange.vue'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BCol,
    BImg,
    BLink,
    BAvatar,
    BAlert,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    UpdateModal,
    TableTimeRange,
    remarkModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      keyArray: [],
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      return this.tableColumns
        .filter(f => f.searchable)
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (前往)分站詳情
    linkBranchInfo(item) {
      const auth = this.checkAuthAbility('branch', 'API.Admin.Branch.Id')
      if (!auth) return
      this.$router.push({ name: 'admin-branch-view', params: { id: item.branch_id } })
    },

    // (前往)會員詳情
    linkCustomerInfo(item) {
      const auth = this.checkAuthAbility('customer', 'API.Admin.Customer.Id')
      if (!auth) return
      this.$router.push({ name: 'admin-customer-view', params: { id: item.id } })
    },

    // (觸發)備註
    onSubmitRemark(item) {
      const resolveArray = []
      resolveArray.push(`customer-${item.id}`)
      this.keyArray = resolveArray
      const auth = this.checkAuthAbility('remark', 'API.Admin.Remark')
      if (!auth) return
      setTimeout(() => { this.$refs.customerRemarkExplorerModal.getData() }, 200)
    },

    // (觸發)新增/編輯會員
    onSubmitUpdate(item) {
      if (!item) {
        this.selected = {}
        const auth = this.checkAuthAbility('customer', 'API.Admin.Customer.Create')
        if (!auth) return
        setTimeout(() => { this.$refs.updateModal.getData() }, 200)
        return
      }
      this.selected = item
      const auth = this.checkAuthAbility('customer', 'API.Admin.Customer.Update')
      if (!auth) return
      setTimeout(() => { this.$refs.updateModal.getData() }, 200)
    },

    // (觸發)更新狀態
    refreshCustomerOnlineStatus: (id, status) => {
      if (!id || !this.$refs.refDataListTable) return
      const tableData = this.$refs.refDataListTable.$data.localItems
      const index = tableData.findIndex(item => item.id === id)
      if (index !== -1) {
        tableData[index].online_status = status
      }
    },
  },
  setup() {
    const CUSTOMER_ADMIN_STORE_MODULE_NAME = 'admin-customer'

    if (!store.hasModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      searchState,
      searchBranch,
      searchKycState,
      branchOptions,
      timeRange,

      setCustomerDelete,
      getCustomerListData,
    } = useCustomerList()

    const {
      ui,
      stateOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    } = useCustomerSetting()

    const {
      useAlertToast,
    } = useAlert()

    const dateConfig2 = {
      locale: language.zh_tw,
      enableTime: false,
      mode: 'range',
      dateFormat: 'Y-m-d',
    }

    return {
      ui,
      useAlertToast,
      dateConfig2,
      stateOptions,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      searchState,
      searchBranch,
      searchKycState,
      branchOptions,
      timeRange,

      setCustomerDelete,
      getCustomerListData,
      refonlineTime,
      onlineTime,
      updateOnline,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}

.user-state-icon {
  max-width: 24px;
  margin-right: 8px;
}

.user-state-alert {
  margin-left: 5px;
  position: relative;
  .alert-icon {
    position: absolute;
    bottom: -5px;
    right: -8px;
    width: 18px;
    transform: scale(1.05);
    animation: btn-breathe .8s linear infinite;
  }
}

.user-state-icon-hidden {
  opacity: 0 !important;
}
</style>
